@footer_height: 48px;

#footer {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    background: @white;
    padding: 0 25px;
    border-top: 1px solid @border_color;
    line-height: @footer_height;
    @media @screen_xlarge {
        .sidebar_main_open &,
        .sidebar_main_active & {
            margin-left: @sidebar_main_width;
        }
    }
}