/* altair plugins */

/* colorpicker */
.cp_altair {
    padding: 8px 0;
    overflow: hidden;
    span {
        width: 24px;
        height: 24px;
        margin: 0 8px 8px 0;
        float: left;
        transition: all 280ms ease-out;
        cursor: pointer;
        border: 1px solid rgba(0,0,0,.15);
        &.active_color {
            border-radius: 50%;
            cursor: default;
        }
    }
}