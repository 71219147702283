/* top bar */
#top_bar {
    background: @white;
    .boxShadowHelper(2);
    height: @top_bar_height;
    .border-box;
    position: fixed;
    top: @header_main_height;
    left: 0;
    right: 0;
    z-index: 100;
    padding: 0 25px;
    .top_bar_nav {
        .reset_list;
        > li {
            float: left;
            text-align: center;
            > a {
                line-height: @top_bar_height;
                padding: 0 16px;
                font-weight: 500;
                color: @text_primary_color;
                display: block;
                &:hover {
                    box-shadow: inset 0 -3px 0 @theme_primary_color;
                }
            }
            &.uk-active a {
                box-shadow: inset 0 -3px 0 @theme_primary_color;
            }
        }
    }
    .uk-slidenav-position {
        &:before,
        &:after {
            position: absolute;
            width: 25px;
            height: 100%;
            display: block;
            top: 0;
            content: '';
        }
        &:before {
            left: -25px;
        }
        &:after {
            right: -25px;
        }
        &:hover .uk-slidenav {
            display: none;
        }
        &.uk-slidenav-hover {
            &:hover {
                .uk-slidenav {
                    display: block;
                }
            }
        }
    }
    .uk-slidenav {
        font-size: 32px;
        height: @top_bar_height + 10;
        width: 24px;
        &-previous {
            left: -25px;
        }
        &-next {
             right: -25px;
        }
    }
}
.top_bar_active {
    padding-top: @header_main_height + @top_bar_height;
    #header_main {
        box-shadow: none;
    }
}