.cr_preview {
    overflow: hidden;
    border: 1px solid #555;
    margin: 8px 8px 8px 0;
    &_lg {
        width: 264px;
        height: 148px;
    }
    &_md {
        width: 140px;
        height: 78px;
    }
    &_sm {
        width: 68px;
        height: 48px;
    }
    &_xs {
        width: 36px;
        height: 36px;
    }
}

.canvasModalImage {
    margin: 0 auto;
    text-align: center;
    > * {
        border: 1px solid #ccc;
        padding: 4px;
    }
}