#breadcrumbs {
    background: @white;
    .reset_list;
    overflow: hidden;
    white-space: nowrap;
    > li {
        display: inline-block;
        a,
        span {
            position: relative;
            display: block;
            font-size: 16px;
            padding: 8px 0;
            line-height: 24px;
        }
        + li {
            a,
            span {
                padding-left: 40px;
                &:before {
                    position: absolute;
                    top: 50%;
                    left: 8px;
                    margin-top: -12px;
                    content: '\e315';
                    font-family: "Material Icons";
                    font-size: 24px;
                    display: block;
                    color: @text_secondary_color;
                }
            }
        }
    }
}