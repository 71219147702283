/* pricing tables */
.pricing_table {
    &.pricing_table_a {
        text-align: center;
        .pricing_table_plan {
            font-size: 18px;
            font-weight: 400;
            padding: 16px 0;
            margin-bottom: 16px;
        }
        .pricing_table_price {
            padding: 8px 0 0;
            font-size: 48px;
            margin-bottom: 24px;
            .currency {
                vertical-align: top;
                font-size: 24px;
                padding: 0 4px;
            }
            .period {
                font-size: 14px;
                padding: 4px;
                color: @text_muted;
                display: block;
            }
        }
        .pricing_table_features {
            .reset_list();
            li {
                font-size: 16px;
                padding: 8px 0;
            }
        }
        .pricing_table_select {
            padding: 32px 0;
        }
    }
    &.pricing_table_b {
        text-align: center;
        .pricing_table_plan {
            font-size: 16px;
            font-weight: 400;
            padding: 16px 0;
            margin-bottom: 16px;
            text-transform: uppercase;
        }
        .pricing_table_price {
            padding: 8px 0 0;
            font-size: 48px;
            margin-bottom: 24px;
            .currency {
                vertical-align: top;
                font-size: 24px;
                padding: 0 4px;
            }
            .period {
                font-size: 14px;
                padding: 4px;
                color: @text_muted;
                display: block;
            }
        }
        .pricing_table_features {
            .reset_list();
            li {
                font-size: 16px;
                padding: 8px 0;
            }
        }
        .pricing_table_select {
            padding: 32px 0;
        }
    }
    &.pricing_table_c {
        text-align: center;
        .pricing_table_plan {
            font-size: 16px;
            font-weight: 400;
            padding: 16px 0;
            margin-bottom: 8px;
        }
        .pricing_table_price {
            padding: 8px 0 0;
            font-size: 48px;
            margin-bottom: 24px;
            .currency {
                vertical-align: top;
                font-size: 24px;
                padding: 0 4px;
            }
            .period {
                font-size: 14px;
                color: @text_muted;
                vertical-align: 0;
                padding-left: 4px;
            }
        }
        .pricing_table_features {
            .reset_list();
            li {
                font-size: 16px;
                padding: 8px 0;
            }
        }
        .pricing_table_select {
            padding: 32px 0;
        }
    }
}