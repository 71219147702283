/* selectize */
@import (less) "../../../bower_components/selectize/dist/css/selectize.css";

.selectize-input {
    border-color: @border_color;
    line-height: 25px;
    padding: 6px 8px;
    &,
    &.focus {
        border-radius: 0;
        box-shadow: none;
        &:before {
            display: none;
        }
    }
    .md-input-danger & {
        border-color: @danger_color;
    }
    &.dropdown-active:before {
        display: none;
    }
}
.selectize-dropdown {
    margin-top: -44px;
    .boxShadowHelper(2);
    color: @text_primary_color;
    z-index: 1210;
    -webkit-transform: translate3d(0,0,0);
    [data-selectable] {
        padding-top: 11px;
        padding-bottom: 10px;
        font-size: 15px;
        &.option-disabled {
            color: @text_muted;
            cursor: not-allowed;
        }
    }
    .active {
        background: @background_color_default;
        color: inherit;
    }
    .optgroup-header {
        font-size: 12px;
        color: @text_muted;
        padding-top: 8px;
        border-bottom: 1px solid @border_color_light;
        text-transform: uppercase;
    }
    &.multi {
        .item-icon {
            margin-right: 4px;
        }
    }
    &.selectize_fs {
        z-index: 9999;
    }
}
.selectize-control {
    .selectize-input {
        min-height: 40px;
    }
    &.multi {
        .selectize-input {
            line-height: 21px;
            > div {
                border-radius: 18px;
                padding: 3px 8px 2px;
                font-size: 14px;
            }
            .selectize-input.has-items {
                padding: 4px;
            }
            .item-icon {
                margin-right: 4px;
            }
            input {
                height: 26px;
                font-size: 15px;
            }
        }
    }
    &.plugin-remove_button [data-value] {
        padding-right: 32px !important;
        .remove {
            border: none;
            width: auto;
            padding: 4px 5px 0 0;
            &:hover {
                background: none;
            }
            &:after {
                color: @text_secondary_color;
                content: '\e5cd';
                font-family: "Material Icons";
                font-size: 18px;
            }
        }
    }
    &.single {
        height: 40px;
        margin-top: 4px;
        .selectize-input {
            border-width: 0 0 1px;
            padding: 8px 8px 9px;
            &:after {
                border: none !important;
                content: '\e5c5';
                font-family: "Material Icons";
                font-size: 28px;
                margin-top: -12px !important;
                right: 28px;
                color: rgba(0,0,0,.3);
            }
            .item {
                font-size: 15px;
            }
            input {
                font-size: 15px;
            }
        }
    }
    &.rtl.single .selectize-input:after {
        left: 28px;
    }
}
.selectize_fix {
    overflow: hidden;
    height: 0;
    width: 100%;
}

.tablesorter {
    .selectize-control {
        &.single,
        .selectize-input {
            min-height: 34px;
        }
        .selectize-input {
            padding: 4px 8px 5px;
        }
    }
}