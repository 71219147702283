@eh_zindex:     2000;

.enjoyhint {
    position: fixed;
    width: 100%;
    height: 100%;
    top:0;
    left: 0;
    z-index: @eh_zindex;
    pointer-events: none;
    overflow: hidden;
    &_hide {
        display: none;
    }
    &_disable_events {
        position:absolute;
        width: 2000px;
        height: 1500px;
        z-index: @eh_zindex + 10;
        pointer-events: all;
        @media @screen_medium_max {
            width: 100%;
            height: 100%;
        }
    }
    &_next_btn,
    &_skip_btn,
    &_btn {
        position: absolute;
        z-index: @eh_zindex + 20;
        pointer-events: all;
        box-sizing: content-box;
        width: 100px;
        height: 46px;
        cursor: pointer;
        margin: 0 auto;
        border-radius: 3px;
        .md_font(500,20px,46px);
        text-align: center;
        text-overflow: clip;
        transition: all 280ms @md_easing;
        text-transform: uppercase;
    }
    &_next_btn {
        color: @text_primary_color;
        background: @white;
        .boxShadowHelper(2);
        &:hover{
            .boxShadowHelper(3);
        }
        &:active{
            .boxShadowHelper(3);
            transition: none;
        }
        @media @screen_medium_max {
            left: 20px !important;
        }
    }
    &_skip_btn{
        color: @md-color-light-blue-400;
        &:hover{
            background: rgba(0,0,0,.1);
        }
        &:active{
            background: rgba(0,0,0,.1);
            transition: none;
        }
        @media @screen_medium_max {
            right: 20px !important;
            left: auto !important;
        }
    }
    &_close_btn {
        display: inline-block;
        position: absolute;
        z-index: @eh_zindex + 20;
        pointer-events: all;
        -webkit-box-sizing: content-box;
        -moz-box-sizing: content-box;
        box-sizing: content-box;
        width: .3em;
        height: .3em;
        border-radius: 1em;
        font: 400 8em/normal Arial,Helvetica,sans-serif;
        color: rgba(0,0,0,1);
        text-overflow: clip;
        background: rgba(0,0,0,0);
        border: 2px solid @white;
        transition: all 280ms @md_easing;
        &:before {
            display: inline-block;
            box-sizing: content-box;
            width: 73%;
            height: 2px;
            position: absolute;
            content: "";
            top: 48%;
            left: 14%;
            border: none;
            font: normal 100%/normal Arial,Helvetica,sans-serif;
            color: rgba(0,0,0,1);
            text-overflow: clip;
            background: #fff;
            text-shadow: none;
            transform: rotateZ(45deg);
        }
        &:after {
            display: inline-block;
            box-sizing: content-box;
            width: 73%;
            height: 2px;
            position: absolute;
            content: "";
            top: 46%;
            left: 15%;
            border: none;
            font: normal 100%/normal Arial,Helvetica,sans-serif;
            color: rgba(0,0,0,1);
            text-overflow: clip;
            background: #fff;
            text-shadow: none;
            transform: rotateZ(-45deg);
        }
        &:hover{
            color: rgba(255,255,255,1);
            border-color: @md-color-red-600;
            cursor: pointer;
        }
        &:active{
            border: 2px solid @white;
            transition: none;
        }
    }
    &_btn {
        box-sizing: content-box;
        width: 150px;
        height: 40px;
        cursor: pointer;
        margin: 0 auto;
        border: 2px solid rgb(30,205,151);
        border-radius: 40px;
        font: normal normal normal 17px/40px "Advent Pro", Helvetica, sans-serif;
        color: rgb(30, 205, 151);
        text-align: center;
        text-overflow: clip;
        letter-spacing: 1px;
        background: rgba(0,0,0,0);
        transition: background-color 0.3s cubic-bezier(0, 0, 0, 0), color 0.3s cubic-bezier(0, 0, 0, 0), width 0.3s cubic-bezier(0, 0, 0, 0), border-width 0.3s cubic-bezier(0, 0, 0, 0), border-color 0.3s cubic-bezier(0, 0, 0, 0);
        &:hover{
            color: rgba(255,255,255,1);
            background: rgb(30, 205, 151);
        }
        &:active {
            border: 2px solid rgba(33,224,163,1);
            background: rgba(33,224,163,1);
            -webkit-transition: none;
            -moz-transition: none;
            -o-transition: none;
            transition: none;
        }
    }
    div.canvas-container{
        position: absolute;
    }
    &_canvas{
        position: absolute;
        z-index: @eh_zindex + 10;
        width: 100%;
        height: 100%;
        pointer-events: none;
    }
    &_svg_wrapper{
        position: absolute;
        width: 100%;
        height: 100%;
        top:0;
        left: 0;
        z-index: @eh_zindex + 10;
        transition: opacity 400ms cubic-bezier(0.42, 0, 0.58, 1);
        svg {
            position: absolute;
            width: 100%;
            height: 100%;
            top:0;
            left: 0;
        }
    }
    &_svg_transparent {
        .enjoyhint_svg_wrapper,
        .enjoy_hint_label{
            opacity:0;
        }
    }
}

.enjoy_hint_label{
    position: absolute;
    color: @white;
    z-index: @eh_zindex + 20;
    .md_font(400,22px,32px);
    transition: opacity 400ms cubic-bezier(0.42, 0, 0.58, 1);
    .material-icons {
        color: @white;
        font-size: 24px;
    }
    @media @screen_medium_max {
        left: 20px !important;
    }
}

#kinetic_container{
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    top:0;
    left: 0;
}

div.kineticjs-content{
    position: absolute !important;
}

.enjoyhint_disable_events {
    position: fixed;
}